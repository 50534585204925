/**
 * Function to find an image with the class of .image-background and set it to the background of the parent container.
 * Should run inside a DOMContentLoaded event listener.
 * 
 * @example
 * setImageBackground()
 * 
 */

function setImageBackground() {
  // get all the elements with the class of .image-background
  const imageBackgrounds = document.querySelectorAll('.image-background');

  // loop through each element
  imageBackgrounds.forEach(function(imageBackground) {
    // get the image url from the element
    const imageUrl = imageBackground.getAttribute('data-image-url');

    // set the background image of the parent container
    imageBackground.style.backgroundImage = `url(${imageUrl})`;
    
  });
}